<template>
    <div>
        <title-bar :title="$t('navigations.it')" />
        <columns-toggle
            :columns-array="defaultColumns"
            :action="showColumnsModal"
            :page="page"
            @clearAction="showColumnsModal = false"
            @init="beforeSetColumns"
            @columnsChange="setColumns"
        />
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="tableActions"
                :addBtnText="$t('messages.addNew')"
                :addBtnLink="$helper.getAddUrl('it')"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @columnsChange="showColumnsModal = true"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:number="data">
                    {{
                        data.value.number
                    }}
                </template>
                <template v-slot:name="data">
                    {{
                        data.value.name
                    }}
                </template>
                <template v-slot:description="data">
                  {{ $helper.truncate(data.value.description) }}
                </template>
                <template v-slot:location="data">
                    {{
                        data.value.location.name
                    }}
                </template>
                <template v-slot:itemCondition="data">
                  {{ $helper.getEnumTranslation("ITEM_CONDITION", data.value.itemCondition, $i18n.locale) }}
                </template>
                <template v-slot:type="data">
                  <div v-if="data.value.type">
                    {{ data.value.type.name }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:model="data">
                    {{
                        data.value.model
                    }}
                </template>
                <template v-slot:department="data">
                  <div v-if="data.value.department">
                    {{ data.value.department.name }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:user="data">
                  <div v-if="data.value.user">
                    {{ data.value.user.givenName + " " + data.value.user.familyName }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:insertedBy="data">
                  <div v-if="data.value.insertedBy">
                    {{ data.value.insertedBy.givenName + " " + data.value.insertedBy.familyName }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                      <edit-btn v-if="canUpdate" :path="$helper.getEditUrl('it', data.value.id)" />
                      <custom-btn v-if="canUpdate" :icon="data.value.itemToOrder ? 'cart-check' : 'cart'" @click="setItemToOrder(data.value)"/>
                      <delete-btn v-if="canDelete" @pressDelete="deleteItem(data.value['@id'])" />
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>

    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import CustomBtn from "@/components/Buttons/CustomBtn.vue";
import ColumnsToggle from "@/components/Table/ColumnsToggle.vue";
import ListUtils from "@/mixins/ListUtils";
import TableLiteUtils from "@/mixins/TableLiteUtils";
export default {
    name: "It",
    components: {
      CustomBtn,
      ColumnsToggle,
      TableLite,
      TablePagination,
      DeleteBtn,
      TableActions,
      EditBtn,
      TitleBar,
      AdvancedFilter
    },
    mixins: [ResourceUtils, ListUtils, TableLiteUtils],
    created() {
        this.setPermissions()
        this.perPage = this.getTableLiteSettingsForPageAndKey(this.page, 'perPage')
        this.load()
    },
    methods: {
        setColumns(data) {
          this.columns = data
          this.setTableLiteSettings(this.page, {columns: this.columns})
        },
        beforeSetColumns() {
          const columns = this.getTableLiteSettingsForPageAndKey(this.page, 'columns')
          if (columns && columns.length > 0) {
            this.defaultColumns.forEach(element => {
              if (columns.includes(element.field)) {
                element.enabled = true
              } else {
                element.enabled = false
              }
            })
          }
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                itemCategory: 'IT'
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            })
            this.$Items.getCollection({params}, 'it_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
      setPermissions() {
        if (this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('CREATE', 'IT')) {
          this.tableActions.push('addBtn')
        }
        this.canUpdate = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('UPDATE', 'IT')
        this.canDelete = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('DELETE', 'IT')
      },
      setItemToOrder(item) {
        item.itemToOrder = !item.itemToOrder
        const body = {
          itemToOrder: item.itemToOrder
        }
        this.update(this.$Items, item.id, body, this.$t('messages.itemUpdated'));
      },
      deleteItem(url) {
          this.deleteByUrl(this.$Skills, url, this.$t('item.deleted'), null, this.load, this.error);
      },
      onChangePage(page) {
        this.currentPage = page;
        this.load();
      },
      perPageChange(num) {
        this.currentPage = 1;
        this.perPage = num;
        this.setTableLiteSettings(this.page, {perPage: this.perPage})
        this.load();
      },
      inputChange(value) {
        this.currentPage = 1;
        this.search = value;
        this.load();
      },
      setSort(offset, limit, order, sort) {
        this.sortable.order = order;
        this.sortable.sort = sort;

        if (order === 'department' || order === 'category' || order === 'location' || order === 'type') {
          this.sortable.order = order + '.name';
        }

        this.load();
      },
    },
    data() {
        return {
            search: "",
            page: 'it',
            isLoading: false,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "id",
                sort: "asc",
            },
          canUpdate: false,
          canDelete: false,
          tableActions: ['search', 'perPage', 'advancedFilter', 'columnsBtn'],
            filterFields: [
                {
                    label: 'forms.number',
                    name: "number",
                    value: null,
                    type: "text",
                },
                {
                    label: 'forms.name',
                    name: "name",
                    value: null,
                    type: "text",
                },
                {
                    label: "forms.location",
                    name: "location",
                    value: null,
                    type: "customSelect",
                    resource: this.$EntityOptions,
                    resourceType: "entityOption",
                    entityType: "LOCATION"
                },
                {
                    label: "forms.condition",
                    name: "condition",
                    value: null,
                    type: "customSelect",
                    resource: this.$Enums,
                    resourceType: "enum",
                    enumName: 'ITEM_CONDITION'
                },
                {
                    label: "forms.department",
                    name: "department",
                    value: null,
                    type: "customSelect",
                    resource: this.$EntityOptions,
                    resourceType: "entityOption",
                    entityType: "DEPARTMENT"
                },
                {
                  label: "forms.type",
                  name: "type",
                  value: null,
                  type: "customSelect",
                  resource: this.$EntityOptions,
                  resourceType: "entityOption",
                  entityType: "TYPE"
                },
                {
                    label: 'forms.model',
                    name: "model",
                    value: null,
                    type: "text",
                },
                {
                    label: 'forms.department',
                    name: "department",
                    value: null,
                    type: "text",
                },
                {
                    label: "forms.user",
                    name: "user",
                    value: null,
                    type: "customSelect",
                    resource: this.$Users,
                    resourceType: "employee",
                },
                {
                    label: "forms.insertedBy",
                    name: "insertedBy",
                    value: null,
                    type: "customSelect",
                    resource: this.$Users,
                    resourceType: "employee",
                },
            ],
            advancedParams: [],
            showColumnsModal: false,
            columns: [],
            defaultColumns: [
                {
                    label: this.$t("forms.number"),
                    field: "number",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.name"),
                    field: "name",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                    enabled: false,
                },
                {
                    label: this.$t("forms.location"),
                    field: "location",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.condition"),
                    field: "itemCondition",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.type"),
                    field: "type",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.model"),
                    field: "model",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.department"),
                    field: "department",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.serviceTag"),
                    field: "serviceTag",
                    sortable: true,
                    enabled: false,
                },
                {
                    label: this.$t("forms.user"),
                    field: "user",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.insertedBy"),
                    field: "insertedBy",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "100px",
                    enabled: true,
                },
            ],
            rows: null
        };
    },
};
</script>

